import * as React from "react";
import backgroundImageMünster from '../assets/Schloss_muenster.jpg'; 
import backgroundImagePlaceholder from '../assets/MoreComingSoon.png'; 
import { useMediaQuery } from "react-responsive";

function Locations(){
    const isMobile = useMediaQuery ({ query: `(max-width: 767px)` });

    return (                
        <div className={`bg-white-black-gradient flex flex-col items-center justify-center h-screen text-white px-4 ${isMobile ? "py-5 mt-20" : ""}`}>
            <h1 className="text-6xl sm:text-5xl md:text-5xl mb-4 font-bold text-reserve_violet-100">Locations</h1>
            <h2 className="text-center text-xl md:text-2xl mb-8 text-stone-700">Supported Universities for a better study experience</h2>
            <div className="flex flex-col sm:flex-row gap-20 mt-10 sm:mt-40">
                <div className="bg-cover bg-center rounded-lg w-full sm:w-96 h-64 sm:h-96 flex flex-col justify-end p-4 hover:scale-105 transitionhover-all duration-200" 
                    style={{backgroundImage: `url(${backgroundImageMünster})`}}>
                    <p className="text-center mb-8 text-2xl sm:text-3xl font-bold">University Münster</p>
                </div>
                <div className="bg-cover bg-center rounded-lg w-full sm:w-96 h-64 sm:h-96 flex flex-col justify-end p-4 hover:scale-105 transitionhover-all duration-200" 
                    style={{backgroundImage: `url(${backgroundImagePlaceholder})`}}>
                    <p className="text-center mb-8 text-black text-2xl sm:text-3xl font-bold"></p>
                </div>
            </div>
        </div>
    );
}

export default Locations;