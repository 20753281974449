import React from "react";
import instagramIcon from '../assets/insta3.png'; 
import emailIconOrange from '../assets/email_orange.png'

function Footer() {

    return (
    <footer className="bg-black-white-gradient pt-40">
      <div className="justify-center items-center flex w-full flex-col pt-12 max-md:max-w-full">
        <div className="text-center text-6xl font-bold self-center whitespace-nowrap mt-12  max-md:mt-10">
          <span className="text-reserve_violet-100">
            reserve
          </span>
          <span className="text-amber-500">
            .
          </span>
        </div>
        <div className="text-white text-center text-base font-light self-center mt-5 mb-20">
          Your personal helper for a smooth study time
        </div>
        <button className="text-white text-center text-xl font-bold whitespace-nowrap shadow-lg justify-center items-stretch bg-gradient-to-r from-amber-500 to-reserve_violet-100 self-center mt-16 px-8 py-4 rounded-[30px] max-md:mt-10 max-md:pl-6 max-md:pr-5 transform hover:scale-105 transition-all duration-200">
          <a href="#navbar">
            Join Now
          </a>                     
        </button>
      
        <div className=" flex-col fill-[linear-gradient(90deg,rgba(59,0,255,0.50)_-5.25%,rgba(67,100,247,0.50)_58.1%,rgba(251,188,5,0.50)_121.44%)] overflow-hidden self-stretch relative flex min-h-[300px] w-full items-stretch mt-24 pt-4 max-md:max-w-full max-md:mt-10">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/d51bf95592c476e3292ae69cd3f01088fbb6ad35253fc06a2b27adf435d95a40?apiKey=0728f582a8894e8ea254adb5ab83d638&"
            className="absolute h-full w-full object-cover object-center inset-0 max-md:max-w-full"
            alt = "Footer wave 1"
          />
          <div className="flex-col  fill-[linear-gradient(90deg,#0C21C1_0%,#4364F7_50%,#F6990C_100%)] overflow-hidden flex min-h-[300px] w-full justify-center items-stretch py-px max-md:max-w-full">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/f2850357b754da9ea710b0586ddb3afdcbcb80f09947bc558b2d1f153b80803d?apiKey=0728f582a8894e8ea254adb5ab83d638&"
              className="absolute h-full w-full object-cover object-center inset-0"
              alt = "Footer wave 2"
            />
            <div className="flex-col  fill-[linear-gradient(90deg,rgba(59,0,255,0.80)_0%,rgba(67,100,247,0.80)_50%,rgba(246,153,12,0.80)_100%)] overflow-hidden flex min-h-[300px] w-full justify-between gap-5 items-end max-md:max-w-full max-md:flex-wrap max-md:px-5">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/88e59d189ae09db9e34d9c9c4c3e43268a16dbe48f78c2598bffeb9f11535553?apiKey=0728f582a8894e8ea254adb5ab83d638&"
                className="absolute h-full w-full object-cover object-center inset-0"
                alt = "Footer wave 3"
              />
      <div className="absolute inset-0 flex flex-col items-center justify-center">
        <div className="flex flex-col items-center justify-center mt-40 max-md:mt-10">
          <div className="flex flex-row gap-4">
          <button className="relative items-stretch flex max-md:mt-10 shadow-lg hover:scale-105 transitionhover-all duration-200">
            <img
              className="shadow-lg aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
              loading="lazy"              
              src={instagramIcon}              
              alt = "Footer mail icon"  
              onClick={() => window.open("https://instagram.com/reserve.study", "")}             
            />
          </button>
            
          <button className="relative items-stretch flex max-md:mt-10 shadow-lg hover:scale-105 transitionhover-all duration-200">
            <a href="mailto: reserve.study.info@gmail.com">
            <img
              className="shadow-lg aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
              loading="lazy"
              src={emailIconOrange}                            
              alt = "Footer instagram icon"              
            />            
            </a>
          </button>
          </div>
          <div className="relative text-white text-xs font-light mt-24 max-md:mt-1 shadow-lg">
            {/* © 2024 All Rights Reserved */}
            <p>&copy; {new Date().getFullYear()} All Rights Reserved</p>
          </div>
        </div>
      </div>
      </div>    
      </div>
          </div>
        </div>
      
    </footer>
    );
}

export default Footer;